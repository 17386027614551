<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <!-- create form start -->
        <v-card>
          <div style="padding: 2%">
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <div class="col-md-3">
                  <v-text-field
                    v-model="title"
                    :rules="titleRules"
                    label="Title"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-select
                    v-model="city"
                    :items="cityOptions"
                    attach
                    chips
                    label="Cities"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <!-- <b-form-select
                    v-model="minimumSalary"
                    :options="minimumSalariesOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="maximumSalary"
                    :options="maximumSalariesOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select> -->

                  <v-select
                    v-model="salary_range"
                    :items="desiredSalaryOptions"
                    attach
                    chips
                    label="Salary Range"
                  ></v-select>
                </div>
              </div>

              <div class="row" style="margin-top: 4%">
                <div class="col-md-3" style="margin-top: -1%">
                  <v-select
                    v-model="jobType"
                    :items="jobTypesOptions"
                    attach
                    chips
                    label="Job Type"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="jobFunction"
                    :options="jobFunctionsOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
                <div class="col-md-3">
                  <v-select
                    v-model="experience"
                    :items="experienceOptions"
                    attach
                    chips
                    label="Career Level"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="education"
                    :options="educationOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
              </div>

              <div class="row" style="margin-top: 5%">
                <div class="col-md-3" style="margin-top: 1%">
                  <v-text-field
                    v-model="minimumExperience"
                    type="number"
                    label="Minimum experience"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-select
                    v-model="genders"
                    :items="gendersOptions"
                    attach
                    chips
                    label="Gender"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3" style="margin-top: 1%">
                  <v-text-field
                    v-model="noOfVacancies"
                    type="number"
                    label="No. of vacancies"
                    required
                  ></v-text-field>
                </div>
                <div class="col-md-3" style="margin-top: 1%">
                  <v-text-field
                    v-model="applyBy"
                    label="Apply by"
                    prepend-icon="mdi-calendar"
                    type="date"
                  ></v-text-field>
                </div>
              </div>

              <div class="row" style="margin-top: 5%">
                <div class="col-md-3" style="margin-top: 1%">
                  <v-select
                    v-model="jobShift"
                    :items="jobShiftsOptions"
                    attach
                    chips
                    label="Job shift"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3" style="margin-top: 3%">
                  <b-form-select
                    v-model="industry"
                    :options="industriesOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
                <div class="col-md-3" style="margin-top: 1%">
                  <v-select
                    v-model="require_skills"
                    :items="skillOptions"
                    attach
                    chips
                    label="Required Skills"
                    multiple
                  ></v-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="benefits"
                    :options="benefitsOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
                <div class="col-md-3">
                  <b-form-select
                    v-model="other_requirements"
                    :options="otherRequirementsOptions"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>
                <div class="col-md-3" style="margin-top: 1%"></div>
              </div>

              <div
                class="row"
                style="margin-top: 4%; padding-left: 1%; padding-right: 1%"
              >
                <label>Description</label>
                <vue-editor
                  style="width: 100%"
                  v-model="description"
                ></vue-editor>
              </div>

              <div
                class="row"
                style="margin-top: 8%; padding-left: 1%; padding-right: 1%"
              >
                <label>Application Procedure</label>
                <vue-editor
                  style="width: 100%"
                  v-model="applicationProcedure"
                ></vue-editor>
              </div>

              <div
                class="row"
                :style="
                  index == 0
                    ? 'margin-top: 8%; '
                    : 'margin-top: 0%; ' + 'padding-left: 1%; padding-right: 1%'
                "
                v-for="(item, index) in qa"
                :key="index"
              >
                <div class="col-md-3">
                  <v-text-field
                    v-model="item.question"
                    label="Question"
                  ></v-text-field>
                </div>
                <div class="col-md-3" style="margin-top: 1%">
                  <b-form-select
                    v-model="item.type"
                    :options="qaTypes"
                    size="sm"
                    class="mt-3"
                  ></b-form-select>
                </div>

                <div class="col-md-3" style="margin-top: 1%">
                  <v-combobox
                    v-if="item.type == 'multi'"
                    v-model="item.answer"
                    label="Options"
                    multiple
                    dense
                    size="sm"
                    class="mt-3"
                    chips
                    @change="test()"
                  ></v-combobox>

                  <v-checkbox
                    v-model="item.mandatory"
                    label="Mandatory?"
                    required
                  ></v-checkbox>
                </div>

                <div class="col-md-3" style="margin-top: 1%">
                  <v-btn
                    class="
                      btn btn-sm btn-success
                      font-weight-bold
                      py-2
                      px-3 px-xxl-5
                      my-1
                    "
                    fab
                    small
                    @click="addNewQuestionRow"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="index > 0"
                    class="
                      btn btn-sm btn-danger
                      font-weight-bold
                      py-2
                      px-3 px-xxl-5
                      my-1
                    "
                    fab
                    small
                    @click="removeQuestionRow(index)"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </div>
              </div>

              <div class="row">
                <v-checkbox
                  v-model="job4uPost"
                  label="job4u.pk Network"
                  required
                ></v-checkbox>

                <v-checkbox
                  style="margin-left: 3%"
                  v-model="myUniPost"
                  label="myUni Network"
                  required
                ></v-checkbox>

                <v-checkbox
                  style="margin-left: 3%"
                  v-model="show_salary_to_login_users"
                  label="show salary to login users only?"
                  required
                ></v-checkbox>

                <div class="col-md-3">
                  <v-select
                    v-if="myUniPost"
                    v-model="my_uni_selected_universities"
                    :items="universitiesOptions"
                    attach
                    chips
                    label="University to post the job"
                    multiple
                  ></v-select>
                </div>
              </div>

              <div style="margin-top: 5%">
                <v-btn
                  class="
                    btn btn-sm btn-success
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  @click="createJob(false)"
                >
                  Update Job
                </v-btn>
                <v-btn
                  style="margin-top: 6%"
                  class="
                    btn btn-sm btn-info
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  @click="createJob(true)"
                >
                  Save Job as Draft
                </v-btn>
                <v-btn
                  style="margin-left: 6%"
                  class="
                    btn btn-sm btn-danger
                    font-weight-bold
                    py-2
                    px-3 px-xxl-5
                    my-1
                  "
                  @click="cancel"
                >
                  Cancel
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card>
        <!-- create form end -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";

export default {
  data() {
    return {
      search: "",
      loading: true,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,

      job4uPost: false,
      myUniPost: false,
      my_uni_selected_universities: null,
      universitiesOptions: [
        { value: "VU", text: "Virtual University of Pakistan" },
        { value: "PU", text: "University of Punjab" },
      ],

      // selection options
      id: null,
      userId:
        localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")).id
          : null,
      title: null,
      city: null,
      minimumSalary: null,
      maximumSalary: null,
      jobType: null,
      jobFunction: null,
      experience: null,
      education: null,
      minimumExperience: null,
      genders: null,
      jobShift: null,
      jobShiftsOptions: [],
      require_skills: null,
      skillOptions: [],
      noOfVacancies: null,
      applyBy: new Date().toISOString().substr(0, 10),
      description: null,
      applicationProcedure: null,
      qa: [{ question: "", type: null, answer: "", mandatory: false }],

      // data
      cityOptions: [],
      minimumSalariesOptions: [],
      maximumSalariesOptions: [],
      jobTypesOptions: [],
      jobFunctionsOptions: [],
      experienceOptions: [],
      educationOptions: [],
      gendersOptions: [],
      qaTypes: [
        { value: null, text: "Select Type" },
        { value: "radio", text: "Options: Yes, No" },
        { value: "multi", text: "Multiple Options" },
        { value: "textarea", text: "Free Text" },
      ],
      titleRules: [
        (v) => !!v || "Title is required",
        (v) => (v && v.length <= 10) || "Title must be less than 25 characters",
      ],
      data: [],
      industry: null,
      industriesOptions: [],
      desiredSalaryOptions: [],
      salary_range: [],
      show_salary_to_login_users: null,
      draft: false,
      benefits: null,
      benefitsOptions: [],
      other_requirements: null,
      delete_after_expiry: false,
    };
  },
  components: {
    VueEditor,
  },
  mounted() {
    const {
      id,
      job_title,
      location_city,
      salary_min,
      salary_max,
      job_type,
      job_function,
      experience,
      education,
      minimum_experience_years,
      gender,
      no_of_vacancies,
      apply_by,
      job_description,
      application_procedure,
      questions,
      job4u_post,
      my_uni_post,
      my_uni_selected_universities,
      job_shift,
      industry,
      require_skills,
      show_salary_to_login_users,
      draft,
      benefits,
      other_requirements,
      delete_after_expiry,
    } = JSON.parse(localStorage.getItem("job_edit_obj"));
    questions.forEach((element) => {
      element.answer = JSON.parse(element.answer);
    });
    this.id = id;
    this.title = job_title;
    this.city = location_city;
    this.minimumSalary = salary_min;
    this.maximumSalary = salary_max;
    this.salary_range = salary_min + " - " + salary_max;
    this.jobType = job_type;
    this.jobFunction = job_function;
    this.experience = experience;
    this.education = education;
    this.minimumExperience = minimum_experience_years;
    this.genders = gender;
    this.jobShift = job_shift;
    this.industry = industry;
    this.require_skills = require_skills;

    this.noOfVacancies = no_of_vacancies;
    this.applyBy = apply_by;
    this.description = job_description;
    this.applicationProcedure = application_procedure;
    this.qa = questions;

    this.job4uPost = job4u_post == 1 || job4u_post == "1" ? true : false;
    this.myUniPost = my_uni_post == 1 || my_uni_post == "1" ? true : false;
    this.show_salary_to_login_users =
      show_salary_to_login_users == 1 || show_salary_to_login_users == "1"
        ? true
        : false;

    this.draft = draft == 1 || draft == "1" ? true : false;
    this.benefits = benefits;
    this.other_requirements = other_requirements;
    this.delete_after_expiry = delete_after_expiry;

    this.my_uni_selected_universities = my_uni_selected_universities;

    this.jobPreRequisite();
  },
  methods: {
    addNewQuestionRow() {
      this.qa.push({ question: "", type: null, answer: "", mandatory: false });
    },
    removeQuestionRow(index) {
      var temp = [];
      this.qa.forEach((element, i) => {
        if (i !== index) temp.push(element);
      });
      this.qa = temp;
    },
    cancel() {
      this.$router.push({ name: "recruiter-jobs" });
    },

    jobPreRequisite() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-pre-requisite`)
        .then((res) => {
          var s = [
            "Upto 20,000",
            "20,001 - 40,000",
            "40,001 - 60,000",
            "60,001 - 80,000",
            "80,001 - 100,000",
            "100,001 - 125,000",
            "125,001 - 150,000",
            "150,001 - 175,000",
            "175,001 - 225,000",
            "225,001 - 275,000",
            "275,001 - 300,000",
            "300,000 - 350,000",
            "350,000 +",
            // "10,000 - 20,000",
            // "20,001 - 30,000",
            // "30,001 - 40,000",
            // "40,001 - 50,000",
            // "50,001 - 60,000",
            // "60,001 - 70,000",
            // "70,001 - 80,000",
            // "80,001 - 90,000",
            // "90,001 - 100,000",
            // "100,001 - 120,000",
            // "120,001 - 140,000",
            // "140,001 - 160,000",
            // "160,001 - 180,000",
            // "180,001 - 200,000",
            // "200,001 - 220,000",
            // "220,001 - 240,000",
            // "240,001 - 260,000",
            // "260,001 - 280,000",
            // "280,001 - 300,000",
          ];
          s.map((el, i) => {
            this.desiredSalaryOptions.push({
              text: el,
              value: el,
            });
          });
          this.desiredSalaryOptions.unshift(
            ...[{ text: "Any salary", value: "Any salary" }]
          );

          this.cityOptions = res.data.cities;
          // this.cityOptions.unshift(...[{ value: null, text: "choose city" }]);

          this.minimumSalariesOptions = res.data.minimumSalaries;
          this.minimumSalariesOptions.unshift(
            ...[{ value: null, text: "minimum salary" }]
          );

          this.maximumSalariesOptions = res.data.maximumSalaries;
          this.maximumSalariesOptions.unshift(
            ...[{ value: null, text: "maximum salary" }]
          );

          this.jobTypesOptions = res.data.jobTypes;
          // this.jobTypesOptions.unshift(...[{ value: null, text: "job type" }]);

          this.jobFunctionsOptions = res.data.jobFunctions;
          this.jobFunctionsOptions.unshift(
            ...[{ value: null, text: "job function" }]
          );

          this.experienceOptions = res.data.experience;
          // this.experienceOptions.unshift(
          //   ...[{ value: null, text: "experience" }]
          // );

          this.educationOptions = res.data.education;
          this.educationOptions.unshift(
            ...[{ value: null, text: "education" }]
          );

          this.industriesOptions = res.data.industries;
          this.industriesOptions.unshift(
            ...[{ value: null, text: "job industry" }]
          );

          this.gendersOptions = res.data.genders;

          this.jobShiftsOptions = res.data.jobShifts;

          this.skillOptions = res.data.skills;
          //   this.gendersOptions.unshift(...[{ value: null, text: "gender" }]);

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createJob() {
      if (draft == true) {
        this.draft = true;
      } else {
        this.draft = false;
      }
      if (this.job4uPost == false && this.myUniPost == false) {
        Swal.fire({
          title: "",
          text: "Please select at-least one platform to submit the job!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      if (
        this.title == null ||
        (this.title == "" && this.city == null) ||
        (this.city == "" && this.minimumSalary == null) ||
        (this.minimumSalary == "" && this.maximumSalary == null) ||
        (this.maximumSalary == "" && this.jobType == null) ||
        (this.jobType == "" && this.jobFunction == null) ||
        (this.jobFunction == "" && this.education == null) ||
        (this.education == "" && this.minimumExperience == null) ||
        (this.minimumExperience == "" && this.genders == null) ||
        (this.genders == "" && this.noOfVacancies == null) ||
        (this.noOfVacancies == "" && this.applyBy == null) ||
        (this.applyBy == "" && this.description == null) ||
        (this.description == "" && this.applicationProcedure == null) ||
        (this.applicationProcedure == "" && this.industry == null)
      ) {
        Swal.fire({
          title: "",
          text: "Please, fill all fields!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/jobs/${this.id}`, {
          user_id: this.userId,
          draft: this.draft == true ? 1 : 0,
          benefits: this.benefits,
          other_requirements: this.other_requirements,
          delete_after_expiry: this.delete_after_expiry,
          job_title: this.title,
          location_city: this.city,
          salary_min: this.salary_range.split(" -")[0],
          salary_max: this.salary_range.split("- ")[1],
          job_type: this.jobType,
          job_function: this.jobFunction,
          experience: this.experience,
          minimum_experience_years: this.minimumExperience,
          education: this.education,
          gender: this.genders,
          job_shift: this.jobShift,
          no_of_vacancies: this.noOfVacancies,
          apply_by: this.applyBy,
          require_skills: this.require_skills,
          job_description: this.description,
          application_procedure: this.applicationProcedure,
          questions: this.qa,
          job4u_post: this.job4uPost == true ? 1 : 0,
          my_uni_post: this.myUniPost == true ? 1 : 0,
          show_salary_to_login_users:
            this.show_salary_to_login_users == true ? 1 : 0,
          my_uni_selected_universities: this.my_uni_selected_universities,
          industry: this.industry,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          Swal.fire({
            title: "",
            text: "Job updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          setTimeout(() => {
            this.$router.push({ name: "recruiter-jobs" });
          }, 3000);
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
};
</script>
